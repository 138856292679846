/*.ant-layout-header {background-color: #001529;}*/
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root,
section {
  height: 100%;
}

:where(.css-dev-only-do-not-override-qpp7lc).ant-layout-header {
  height: 73px;
}

.sign-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: block;
}
.text-center {
  text-align: center;
}
.ant-card .ant-card-body {
  padding: 8px;
}
.cardBrand {
  margin: 0 10px;
}
.ant-card .cardBrand .ant-card-body {
  padding: 0px;
}

.ant-card-body h3 {
  margin: 0;
}
.ant-card-body h5 {
  margin: 0;
}

.ant-layout-header {
  background-color: #ffffff;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 73px;
}
.ant-layout-header {
  padding-inline: 10px;
}
.header h1 {
  font-size: 2rem;
  font-weight: 100;
  color: #000000;
}
.header button {
  background-color: #ffffff;
  color: #000000;
  box-shadow: none;
}

.brand {
  background: #f5f5f5;
}

.padding-content {
  padding: 40px;
}

.copyright {
  color: #858b9d;
}

.card-login {
  padding: 24px;
  margin: 0;
  min-height: 280;
  background: #fff;
  justify-content: center;
}

.login-form-forgot {
  color: #5fc266;
  float: right;
}

.btn-enter {
  background: #001529;
  color: #fff;
  border: 0;
  width: 100%;
}

.btn-adm {
  background: #5fc266;
  color: #fff;
}

.btn-adm:hover {
  background: #fff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.ant-table-cell > .asLink {
  color: #5fc266;
}

.icon-user-logged {
  font-size: 1rem;
  color: #fff;
  border-radius: 50%;
  background-color: #5fc266;
  padding: 10px;
}

.btn-cancel-test {
  color: #001529 !important;
  border-color: #001529 !important;
}

.ant-dropdown-button > button:first-child {
  width: 100%;
}

.space-results .ant-space-item:nth-child(4) {
  margin-inline-start: auto;
}

.ant-progress-inner span {
  font-size: 1rem;
}

.cost-per-day {
  padding: 15px;
  border: 1px solid #5fc266;
  border-radius: 5px;
  margin-bottom: 20px;
}

.promotion-title {
  margin-block: 0 !important;
  font-weight: bolder !important;
}

div.ant-typography {
  color: #767676;
  font-weight: 200;
}
.rancho-data .ant-input-group-addon {
  min-width: 12rem;
}

.ant-popover-content {
  max-width: 50%;
  min-width: 300px;
}

.ant-badge.ant-badge-status .ant-badge-status-dot {
  width: 20px;
  height: 20px;
}

.info_paragraph {
  color: #767676;
  font-weight: 200;
}

.ant-btn-background-ghost {
  width: 100%;
}

.readOnlyTable .ant-table{
  background: whitesmoke;
}

.tiny-block {
  line-height: 0.5rem;
  padding-bottom: 10px;
}
/*
.tiny-block button {
  font-family: monospace;
  padding: 0 2px;
  margin: 0 3px;
  border-radius: 0px;
  outline: none;
  border: none;
}
*/
.tiny-block button {
  border: none;
  border-radius: 50%;
  margin: 0 3px;
  height: 35px;
  width: 35px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}
.tiny-block button.MD {
  background-color: #0000ff;
  color: #f5f5f5;
}
.tiny-block button.D {
  background-color: #fadb14;
}
.tiny-block button.Ó {
  background-color: #52c41a;
}
.tiny-block button.E {
  background-color: #fa8c16;
}
.tiny-block button.ME {
  background-color: #f5222d;
}


@media (min-width: 320px) and (max-width: 400px) {
  .space-results .ant-space-item:nth-child(3) {
    margin-inline-start: initial;
  }
  .ant-layout-content {
    padding: 0 0;
  }
  .cardBrandContainer {
    text-align: center;
    justify-content: center;
  }
  h1,
  .info_paragraph {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/*To enable background printing i*/
body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust:exact !important;
}
.just-print{display: none;}

.ant-table-cell h5:nth-last-of-type(1){
  color: #52c41a;
}

.short-price {
  font-size: 0.7rem;
  position: absolute;
  top: 23px;
  color: #999;
}

@media print {
  .no-print{display: none;}
  aside {display: none;}
  main {margin: 0;}
  body {zoom: 80%;}
  button,
  .ant-anchor-wrapper {
    height: 0px;
    visibility: hidden;
  }
  .space-results {
    display: none;
  }
  #evaluacion-por-etapa {
    position: block;
  }
  #evaluacion-por-etapa .col-section .ant-row {
    position: relative;
    display: inline-block;
  }
  #evaluacion-por-etapa .col-section > .ant-row > .ant-col {
    display: inline-block;
    max-width: 1.5in;
  }
  .just-print{display: inline;}

  .costos_generales .ant-table{
    background: whitesmoke;
  }
  .costos_generales .ant-table .ant-table-cell {
    font-size: 0.83em;
    padding: 0px 16px !important;
  }
  .costos_generales .ant-table .ant-table-thead .ant-table-cell {
    font-size: 14px;
    padding: 10px 16px !important;
  }
}
